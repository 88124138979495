import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Grid, Container, Button } from "@mui/material";
import { tableIcons } from "../../../Utility/utility";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { baseAddressURL } from "../../../Utility/utility";
import { getDyanamicFieldListTableData } from "../../../Redux/API/api_userManagemnt";
import { useSelector } from "react-redux";
//import { baseAddressURL, tableIcons } from "../../../Utility/utility";
import Loader from "../../../Components/Loader/Loader";
const staticData = [
  {
    1: "Tracker Type",
    2: "Dropdown",
    3: "Active",
  },
  {
    1: "FP",
    2: "Dropdown",
    3: "Active",
  },
  {
    1: "S-Office",
    2: "Multi-Select",
    3: "Active",
  },
  {
    1: "Access TYpe",
    2: "Multi-Select",
    3: "Active",
  },
];

export default function ToolList() {
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  const siteToken = useSelector((state) => state.saveTokenReducer);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer); //azure-token
  const UID = useSelector((state) => state.saveUserIdReducer);
  const defaultMaterialTheme = createTheme();
  const [routes, setRoutes] = useState([]);
  let [isLoader, setIsLoader] = useState(false);

  const columnsForm = [
    { title: "Field Name", field: "dynamicFieldName" },
    {
      title: "Field Type",
      field: "dynamicFieldType.dynamicFieldTypeName",
    },

    {
      title: "Status",
      field: "isActive",
    },
  ];

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }
  function clickEditHardCodeMaster(rowData) {
    let id = rowData.dynamicFieldId;

    let idencrypted = btoaSecure(id);
    window.location.href =
      baseAddressURL + "/adddynamicfields?id=" + idencrypted;
  }
  //GetAllSubLevelData
  const [data, setData] = useState([]);
  function getDyanamicTypeMenus() {
    setIsLoader(true);
    getDyanamicFieldListTableData(
      siteToken,
      azureToken.token,
      UID.id,
      userDetails.userEmail,
      userDetails.gid,
      userDetails.roleId
    )
      .then((response) => {
        setData(response);

        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getDyanamicTypeMenus();
  }, []);

  return (
    <div className="main-component ">
      <Container maxWidth="xl" className="">
        <Grid container spacing={2}>
          <Grid item xs={12} className="req-grid master">
            <MaterialTable
              title="Dynamic Field List"
              columns={columnsForm}
              data={data}
              icons={tableIcons}
              options={{
                search: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 20, 50],
                pageSize: 10,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                exportAllData: true,
                exportFileName: "Dynamic Field List",
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#F2FCFC" };
                  } else {
                    return { backgroundColor: "white" };
                  }
                },
              }}
              actions={[
                {
                  icon: AddIcon,
                  isFreeAction: true,
                  tooltip: "Add New Field",
                  onClick: () => {
                    window.location.href = "/AddDynamicFields";
                  },
                },
                {
                  icon: EditIcon,
                  tisFreeAction: true,
                  tooltip: "Edit Registered Field",
                  onClick: (event, rowData) => clickEditHardCodeMaster(rowData),
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-1 d-flex jc-flex-start pb-1">
          <Grid item xs={6} md={1}>
            <Link to={"/administration"}>
              <Button fullWidth className="pt-button--secondary">
                &lt; &lt; Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      {isLoader ? <Loader /> : <></>}
    </div>
  );
}
