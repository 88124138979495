import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Tooltip,
  Zoom,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SiemensLogoGreen from "../../assets/images/siemens-logo-white.svg";
import "./Header.scss";
import IntraLogo from "../../assets/images/sie-intranet-white-rgb.svg";
import { useSelector } from "react-redux";

import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appStyle: {
    backgroundColor: `#099 !important`,
    color: `var(--dark-blue)`,
  },
  menuButton: {
    marginRight: `16px`,
  },
  IntraLogo: {
    height: `1rem`,
    margin: ` 0 10px`,
  },
}));

function logoutUser(auth, dispatch) {
  var newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: newurl }, "", `/`);
  localStorage.clear();
  sessionStorage.clear();
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  // if (auth !== undefined) {
  //   //auth.removeUser();
  //   // // dispatch(saveAccessTokenAction(''));
  //   dispatch(storeUserDetailsAction(""));

  // }
  window.location.href = "/";
}

//The "accountHolderDetails" must be updated with the credentials received from MyID

export default function Header() {
  const classes = useStyles();
  const { instance } = useMsal();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  const handleLogoutRedirect = () => {
    localStorage.clear();
    sessionStorage.clear();
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  let [appHeaderText] = useState(
    window.innerWidth > 720 ? "Access Management Tool" : "AMT"
  );

  return (
    <div className="header-grow">
      <AppBar position="fixed" className={classes.appStyle}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            style={{ margin: "0 0 5px 0" }}></IconButton>

          <Link to="/">
            <img
              src={SiemensLogoGreen}
              alt="Siemens"
              className="official-logo"
            />
          </Link>

          <div className={classes.search}></div>
          <div className={classes.grow}>
            <h2
              className="text-center"
              style={{
                padding: "0 8rem 0 0",
                color: "#FFF",
              }}>
              {appHeaderText}
              <img
                src={IntraLogo}
                alt="Intra Logo"
                className={classes.IntraLogo}
              />
            </h2>
          </div>
          <div className={classes.sectionDesktop}></div>

          {
            <>
              <Tooltip
                title={
                  userDetails.userFirstName +
                  " " +
                  userDetails.userLastName +
                  ", " +
                  userDetails.roleName
                }>
                <h4 style={{ color: "#FFF", paddingRight: "8px" }}>
                  {userDetails.userFirstName + " " + userDetails.userLastName}
                </h4>
              </Tooltip>

              <Tooltip
                title="Logout"
                TransitionComponent={Zoom}
                enterDelay={100}
                leaveDelay={100}
                onClick={handleLogoutRedirect}>
                <IconButton className="header-icon">
                  <ExitToAppIcon style={{ color: "#FFF" }} />
                </IconButton>
              </Tooltip>
            </>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}
