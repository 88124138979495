export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const saveAzureTokenAction = (token) => {
  return {
    type: "saveAzureToken",
    payLoad: token,
  };
};
export const saveReqNoAction = (reqNo) => {
  return {
    type: "saveReqNo",
    payLoad: reqNo,
  };
};
export const authUserAction = (gid) => {
    return {
        type: "myIDLogin",
        payLoad: gid,
    };
};
export const errorMessageAction = (errorMessage) => {
    return {
        type: "errorMessage",
        payLoad: errorMessage,
    };
};
export const saveUserIdAction = (id) => {
    return {
        type: "encryptedId",
        payLoad: id,
    };
};