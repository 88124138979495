import React from "react";
import { Grid, Link, Button } from "@mui/material";
import "../LoginRegister/LoginRegister.scss";
import OfficialLogo from "../../assets/images/siemens-logo-white.svg";
import IntraLogo from "../../assets/images/sie-intranet-black-rgb.svg";
import { useDispatch } from "react-redux";
//import { useAuth } from "react-oidc-context";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

export default function LoginRegister() {
  const dispatch = useDispatch();
  const { instance } = useMsal();

  function azureADLogin() {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }
  return (
    <div className="bg-login-register">
      <div className="">
        <Grid item xs={12}>
          <div className="amt-lr-logo">
            <img src={OfficialLogo} className="official-logo" alt="Siemens" />
          </div>
        </Grid>
        <Grid container className="amt-lr-mobileContainer">
          <Grid item xs={12} sm={12} md={6} className="amt-lr--project-block">
            <div className="amt-lr--project">
              <p className="amt-lr-project-name">
                Access Management Tool
                {/* <sup>
                  <img
                    src={IntraLogo}
                    alt="amt Intra Logo"
                    className="amt-intra-logo"
                  />
                </sup> */}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={10} md={5} className="amt-lr-master-block">
            <div className="amt-lr-block">
              <div className="amt-login-siemens_user">
                <h2 className="m-0">Siemens Employee Login </h2>
                <span className="">
                  If you are a Siemens Employee, login via Azure Ad
                </span>
                <div className="d-flex jc-center myid-login-btn-block mt-05">
                  <Link to="/">
                    <Button
                      className="pt-button--hero myid-login-btn"
                      id="AzureAdLogin"
                      onClick={azureADLogin}>
                      <span>Ad Login</span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
