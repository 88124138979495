import React, { useState, useEffect } from "react";
import { Grid, Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { GetAllUser } from "../../../Redux/API/api_userManagemnt";
import Loader from "../../../Components/Loader/Loader";
import { baseAddressURL, tableIcons } from "../../../Utility/utility";
import { Container } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";

export default function UserList() {
  let [isLoader, setIsLoader] = useState(false);
  const token = useSelector((state) => state.saveTokenReducer.token);
  const [getUser, setGetUsers] = useState([]);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  

  const siteToken = useSelector((state) => state.saveTokenReducer);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer); //azure-token
  const UID = useSelector((state) => state.saveUserIdReducer);
 
    let userWhilePageload = "";//value was 0 earlier
  function getUsersData() {
    setIsLoader(true);
    GetAllUser(
      siteToken,
      azureToken.token,
      UID.id,
      userDetails.userEmail,
      userDetails.gid,
      userDetails.roleId,
      userWhilePageload
    )
      .then((response) => {
       
        setGetUsers(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function GetEditButton(rowData) {
    return (
      <Tooltip title="Edit User" placement="bottom">
        <span>
          <Button href="javascript:void(0)">
            <span>
              <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  var columns = [
    { title: "Options", field: "view", hidden: true },
    { title: "GID", field: "gid" },
    { title: "User Name", field: "userFirstName" },
   
    { title: "Email Address", field: "userEmail" },
    { title: "Division", field: "divisionName" },
    { title: "Department", field: "departmentName" },
    {
      title: "Tool Name",
      field: "toolListNames",
      align: "left",
      render: (rowData) => {
        return (
          <Tooltip title={rowData.toolListNames}>
            <span>{rowData.toolListNames.slice(0, 10)}</span>
          </Tooltip>
        );
      },
    },
    { title: "Role", field: "roleName" },
    {
      title: "Status",
      field: "activeInactive",
    
    },
  ];

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }

  function clickEditHardCodeMaster(rowData) {
      let id = rowData.guid;//guid-->userId
    //let idEncrypted = btoaSecure(id);
      window.location.href = baseAddressURL + "/addUser?id=" + id;//idEncrypted
  }

  function clickAddUser() {
    window.location.href = baseAddressURL + "/addUser";
  }

  useEffect(() => {
    getUsersData();
  }, []);

  return (
    <div className="main-component">
      <Container className="box-shadow" maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} className="d-flex jc-space-bt">
            <Grid item xs={9} className="text-left ml-1">
              <h3 className="m-0">User Management</h3>
            </Grid>
            <Grid item xs={3} className="text-right"></Grid>
          </Grid>
          <Grid item xs={12} className="req-grid">
            <MaterialTable
              title=""
              columns={columns}
              data={getUser}
              icons={tableIcons}
              options={{
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                search: true,
                exportAllData: true,
                exportFileName: "UserList",
                pageSize: 10,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 20, 30, 50],
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#F2FCFC" };
                  } else {
                    return { backgroundColor: "white" };
                  }
                },
              }}
              actions={[
                {
                  icon: AddIcon,
                  isFreeAction: true,
                  tooltip: "Add New User",
                  onClick: () => {
                    clickAddUser();
                  },
                },
                {
                  icon: EditIcon,
                  tisFreeAction: true,
                  tooltip: "Edit User",
                  onClick: (event, rowData) => clickEditHardCodeMaster(rowData),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={1} className="mb-1">
            <Link to={"/administration"}>
              <Button
                type="submit"
                className="pt-button--tertiary"
                id="SupplierLoginBtn"
                fullWidth>
                &lt; &lt; Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      {isLoader ? <Loader /> : <></>}
    </div>
  );
}
