import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import "./Home.scss";
import { GetCardsList } from "../../Redux/API/api_raiseRequest";
import Loader from "./../../Components/Loader/Loader";

import { useMsal } from "@azure/msal-react";

export default function Home() {
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const { instance } = useMsal();
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer); //azure-token
  const UID = useSelector((state) => state.saveUserIdReducer);
  const [routes, setRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function getDynamicMenus() {
    setIsLoading(true);
    GetCardsList(
      siteToken,
      azureToken.token,
      UID.id,
      userDetails.userEmail,
      userDetails.gid,
      userDetails.roleId
    )
      .then((response) => {
        setIsLoading(false);
          if (response && response !== "InValid Token. Please login again") {
          setRoutes(response);
        }else if (response && response == "InValid Token. Please login again") {
          localStorage.clear();
          sessionStorage.clear();
          instance.logoutRedirect().catch((error) => console.log(error));
        } else {
          setRoutes([]);
        }
      })
      .catch((error) => {
        console.log("Error: " + error, "error");
      });
  }

  useEffect(() => {
    getDynamicMenus();
  }, []);

  return (
    <div className="amt-master-block">
      <Grid container spacing={5} className="d-flex jc-center">
        <Grid item xs={12} className="z-9 pt-0 custom-bg">
          <div className="logged-in-user">
            <h1 className="logged-in-user-heading">
              <span className="logged-in-greetings">Hello,</span>
              <span className="logged-in-user-name">
                {userDetails.userFirstName}
              </span>
            </h1>
            <p className="logged-in-project-desc">
              Welcome to Access Management Tool
            </p>
          </div>
        </Grid>
        {routes.map((item) => (
          <Grid item xs={12} sm={6} md={4}>
            <Link to={item.link}>
              <div className={item.classes} id={item.cardId}>
                <span className={item.spanClass}>{item.name}</span>
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
      {isLoading === true ? <Loader /> : <></>}
    </div>
  );
}
