import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Grid, Container, Button } from "@mui/material";
import { tableIcons } from "../../../Utility/utility";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { GetAllSubLevelData, GetAllToolListData, GetSubLevelDetailsData, getDyamicAllData } from "../../../Redux/API/api_userManagemnt";
import { baseAddressURL } from "../../../Utility/utility";
import Loader from "../../../Components/Loader/Loader";
import { useSelector } from "react-redux";


export default function ToolList() {
  const defaultMaterialTheme = createTheme();
    const [routes, setRoutes] = useState([]);
    let [isLoader, setIsLoader] = useState(false);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);//azure-token
    const UID = useSelector((state) => state.saveUserIdReducer);

    const columnsForm = [
      { title: "Id", field: "id", hidden: true },
        { title: "Tool Name", field: "toolName" },
        { title: "Application Name", field: "applicationName" },
        
      {
        title: "Dynamic Fields",
        field: "dynamicFieldName",
        render: (rowData) => {
          return rowData.dynamicFieldName === ""
            ? "Only GID Required"
            : rowData.dynamicFieldName;
        },
        },


      {
        title: "Status",
        field: "isActive",
       
      },
    ];

    function btoaSecure(value) {
        const { btoa } = require("abab");
        let currDate = new Date().toDateString();
        let valueModified = value + " " + currDate;
        let valueSecured = btoa(btoa(valueModified));
        return valueSecured;
    }
    function clickEditHardCodeMaster(rowData) {
        let id = rowData.toolId;
        let idEncrypted = btoaSecure(id);
        window.location.href = baseAddressURL + "/addtool?id=" + idEncrypted;
    }
    //GetAllSubLevelData
    const [data, setData] = useState([]);
    function getAllToolsData(toolid=0) {
        setIsLoader(true);
        getDyamicAllData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,toolid)
            .then((response) => {
               
                setData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    useEffect(() => {
        getAllToolsData();
    }, []);

  return (
    <div className="main-component ">
      <Container maxWidth="xl" className="">
        <Grid container spacing={2}>
          <Grid item xs={12} className="req-grid">
            <MaterialTable
              title="Registered Tools List"
              columns={columnsForm}
              data={data}
              icons={tableIcons}
              options={{
                search: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 20, 50],
                pageSize: 10,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                exportAllData: true,
                exportFileName: "Tool list",
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#F2FCFC" };
                  } else {
                    return { backgroundColor: "white" };
                  }
                },
              }}
              actions={[
                {
                  icon: AddIcon,
                  isFreeAction: true,
                  tooltip: "Add New Tool",
                  onClick: () => {
                    window.location.href = "/addTool";
                  },
                },
                {
                  icon: EditIcon,
                  tisFreeAction: true,
                  tooltip: "Edit Registered Tool",
                  onClick: (event, rowData) => clickEditHardCodeMaster(rowData),
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-1 d-flex jc-flex-start pb-1">
          <Grid item xs={6} md={1}>
            <Link to={"/administration"}>
              <Button fullWidth className="pt-button--secondary">
                &lt; &lt; Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      {isLoader ? <Loader /> : <></>}
    </div>
  );
}
