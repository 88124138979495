import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, MenuItem, Container } from "@mui/material";
import "../Administration.scss";
import {
    addUserData,
    GetAllUser,
    getDivisionMenus,
    getRoleMenus,
    editUserMgnt,
    getDepartmentMenus,
    getToolList,
    GetGIDBasedData,
} from "../../../Redux/API/api_userManagemnt";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import { useSelector } from "react-redux";

export default function AddUser() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);//azure-token
    const UID = useSelector((state) => state.saveUserIdReducer);

    const token = useSelector((state) => state.saveTokenReducer.token);//siteToken
    const redNo = useSelector((state) => state.saveReqNoReducer.encryptedId);


    //! Fixed Field Hooks
    const [isLoader, setIsLoader] = useState(false);
    const [deptValue, setDeptValue] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [divValue, setDivValue] = useState("");
    const [roleValue1, setRoleValue1] = useState(null);
    const [gid, setGID] = useState("");
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [getDivisions, setGetDivisions] = useState([]);
    const [status, setStatus] = useState(null);
    const [getDepartments, setGetDepartments] = useState([]);
    const [getRoles, setGetRoles] = useState([]);
    const [roleName, setRoleName] = useState("");
    const [firstNameHelper, setFirstNameHelper] = useState("");
    const [lastNameHelper, setLastNameHelper] = useState("");
    const [gidHelper, setGIDHelper] = useState("");
    const [emailHelper, setEmailHelper] = useState("");
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    function getDivision() {
        getDivisionMenus(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId)
            .then((response) => {
                setGetDivisions(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    //GetMstDepartment

    function getDepartment() {
        getDepartmentMenus(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId
        )
            .then((response) => {
                setGetDepartments(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //getRoleMenus

    function getRoleList() {
        getRoleMenus(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId)
            .then((response) => {

                setGetRoles(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    //toollistData
    function getToolData() {
        getToolList(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId)
            .then((response) => {
                setToolsLIst(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function resetGIDbasedFields() {
        setUserName("");
        setUserEmail("");
        setDeptValue("");
    }
    //! GID based Data
    function getGidDetails(gid) {
        setIsLoader(true);
        GetGIDBasedData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            gid,
            userDetails.roleId)
            .then((response) => {

                setUserName(response.result.userName);
                setUserEmail(response.result.email);
                setDeptValue(response.result.department);
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("error", error);
            });
    }



    const handleInputChange = (event) => {
        let valid;
        switch (event.target.name) {
            case "gid":
                setGID(event.target.value);
                valid = /^[a-zA-Z][a-zA-Z0-9]*$/.test(event.target.value);
                if (!valid) {
                    setGIDHelper("Cannot start with Number and Special Character");
                    resetGIDbasedFields();
                } else if (event.target.value.length !== 8) {
                    setGIDHelper("Minimum 8 characters");
                    resetGIDbasedFields();
                } else {
                    setGIDHelper("");
                    getGidDetails(event.target.value);
                }
                break;
            case "firstname":
                setUserName(event.target.value);
                valid = /^[a-zA-Z][a-zA-Z0-9 ]*$/.test(event.target.value);
                if (!valid) {
                    setFirstNameHelper("Cannot contain special characters");
                } else if (event.target.value.length === 0) {
                    setFirstNameHelper("Cannot be left blank");
                } else {
                    setFirstNameHelper("");
                }
                break;
            case "lastName":
                setUserLastName(event.target.value);
                valid = /^[a-zA-Z][a-zA-Z0-9 ]*$/.test(event.target.value);
                if (!valid) {
                    setLastNameHelper("Cannot contain special characters");
                } else if (event.target.value.length === 0) {
                    setLastNameHelper("Cannot be left blank");
                } else {
                    setLastNameHelper("");
                }
                break;
            case "email":
                setUserEmail(event.target.value);
                valid = /^\w+([\.-]?\w+)*@siemens.com$/.test(event.target.value);
                if (!valid) {
                    setEmailHelper("Invalid Email");
                } else {
                    setEmailHelper("");
                }
                break;
            case "department":
                setDeptValue(event.target.value);
                break;

            case "division":
                setDivValue(event.target.value);
                break;

            case "role":
                setRoleValue1(event.target.value);

                if (event.target.value === 9) {
                    setRoleName("Admin");
                } else if (event.target.value === 10) {
                    setRoleName("User");
                } else if (event.target.value === 11) {
                    setRoleName("Approver");
                } else {
                    setRoleName("RPA");
                }
                break;
            case "status":
                setStatus(event.target.value);

                break;
            default:
                break;
        }
    };

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };



    const handleBack = () => {
        window.location.href = "/userlist";
    };

    const [toolsList, setToolsLIst] = useState([]);
    const [selectedOptionsToolsList, setSelectedOptionsToolsList] = useState([]);
    const [toolsListData, setToolsListData] = useState([]);
    const [filterToolsList, setFilterToolsList] = useState("");
    function editToolsLIstByUser(filterToolsList) {
        var arrayToolsList = filterToolsList.split(",");
        setSelectedOptionsToolsList(
            toolsList.filter((x) => arrayToolsList.includes(x.value))
        );
    }


    let toolsListArr = [];
    const [toolsListString, setToolsListString] = useState("");
    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }

    function onChangeToolsList(value, event) {
        let toolsListValue = [];
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
            toolsListValue = [];
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }
        if (event.action === "select-option" && event.option.value === "*") {
            toolsListValue = toolsList;
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            toolsListValue = [];
        } else {
            toolsListValue = value;
        }
        for (let i = 0; i < toolsListValue.length; i++) {
            if (toolsListValue[i].value !== undefined)
                toolsListArr.push(toolsListValue[i].value);
        }
        setToolsListString(toolsListArr.toString());
    }

    const handleClose = (e) => {
        setOpen(false);
        if (alertType === "success" && !encryptedId) {
            window.location.href = "/userList";
        }
        if (alertType === "success" && encryptedId) {
            window.location.reload();
            window.location.href = "/userList";
        }
    };
    //let UID = 10;//UID.id
    function getUsersData(value) {
        setIsLoader(true);
        GetAllUser(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            value
        )
            .then((response) => {
               
                setUserName(response[0].userFirstName);
                setUserLastName(response[0].userLastName);
                setUserEmail(response[0].userEmail);
                setDeptValue(response[0].departmentName);
                setDivValue(response[0].divisionId);
                setGID(response[0].gid);
                setRoleValue1(response[0].roleId);
                setFilterToolsList(response[0].toolListId);

                setStatus(response[0].isActive);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function handleSubmit() {
        let formdata = {
            UserFirstName: userName,
            UserEmail: userEmail,
            Gid: gid,
            RoleId: roleValue1,
            RoleName: roleName,
            DivisionId: divValue,
            DepartmentName: deptValue,
            IsActive: status,
            CreatedBy: userDetails.userId,
            Toollists: toolsListString,
        };
        setIsLoader(true);
        addUserData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdata)
            .then((response) => {
                setIsLoader(false);
                if (response.status === 1) {
                    handleSnackOpen("User Details Added Successfully", "success");

                } else if (response.status === 2) {
                    handleSnackOpen("User Details Already Exist", "error");
                } else {
                    handleSnackOpen("Error while Adding User Details...", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    let formdataEdit = {
        UserFirstName: userName,
        UserEmail: userEmail,
        Gid: gid,
        RoleId: roleValue1,
        DivisionId: divValue,
        DepartmentName: deptValue,
        IsActive: status,
        ModifiedBy: userDetails.userId,
        UserId: userDetails.userId,
        RoleName: roleName,
        Toollists: toolsListString || filterToolsList,
    };
    //editUserMgnt

    function handleEdit() {
        setIsLoader(true);
        editUserMgnt(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataEdit)
            .then((response) => {
                setIsLoader(false);
                if (response.status === 1) {
                    handleSnackOpen("User Details Updated Successfully", "success");

                } else {
                    handleSnackOpen("Error while Updating User Details", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function atobSecure(value) {
        const { atob } = require("abab");
        let arrValue = atob(atob(value)).split(" ");
        let valueActual = arrValue[0];
        return valueActual;
    }

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            if (sParameterName[0] == sParam) {
                return decodeURIComponent(sParameterName[1]);
            }
        }
        // If parameter is not found, return null or empty string
        return null; // or return '';
    }
    var encryptedId = getURLParameter("id");

    useEffect(() => {
        if (encryptedId) {
            //getUsersData(atobSecure(encryptedId));
            getUsersData(encryptedId);
        }
        getDivision();
        getRoleList();
        getDepartment();
        getToolData();
    }, [encryptedId]);

    useEffect(() => {
        editToolsLIstByUser(filterToolsList);
    }, [toolsListData, filterToolsList]);

    return (
        <div className="main-component">
            <Container className="box-shadow" maxWidth="xl">
                <Grid container spacing={2} className="mb-1">
                    {!encryptedId ? (
                        <Grid item xs={12}>
                            <p className="mandatoryAll-remark">All Fields are Mandatory</p>
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item xs={12}>
                        <h3 className="m-0">{encryptedId ? "Update User" : "Add User"}</h3>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="GID"
                            id="gid"
                            value={gid}
                            name="gid"
                            disabled={encryptedId}
                            helperText={gidHelper}
                            inputProps={{ maxLength: 8 }}
                            onChange={handleInputChange}
                            fullWidth
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Name"
                            id="firstname"
                            name="firstname"
                            value={userName}
                            helperText={firstNameHelper}
                            //onChange={handleInputChange}
                            fullWidth
                            variant="filled"
                        />
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Email"
                            name="email"
                            id="email"
                            value={userEmail}
                            helperText={emailHelper}
                            // onChange={handleInputChange}
                            fullWidth
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Department"
                            name="email"
                            id="email"
                            value={deptValue}
                            fullWidth
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Division"
                            id="division"
                            name="division"
                            value={divValue}
                            onChange={handleInputChange}
                            fullWidth
                            select
                            variant="filled">
                            {getDivisions.map((item) => (
                                <MenuItem key={item.divisionId} value={item.divisionId}>
                                    {item.divisionName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Role"
                            id="role"
                            name="role"
                            value={roleValue1}
                            onChange={handleInputChange}
                            fullWidth
                            select
                            InputLabelProps={{ shrink: roleValue1 ? true : false }}
                            variant="filled">
                            {getRoles.map((item) => (
                                <MenuItem key={item.roleId} value={item.roleId}>
                                    {item.roleName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {roleValue1 === 11 ? (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <ReactMultiSelectCheckboxes
                                options={[{ label: "All", value: "*" }, ...toolsList]}
                                placeholderButtonLabel="Tools List"
                                getDropdownButtonLabel={getDropdownButtonLabel}
                                value={selectedOptionsToolsList}
                                onChange={onChangeToolsList}
                                setState={setSelectedOptionsToolsList}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {encryptedId ? (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                label="Status"
                                name="status"
                                value={status}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: status ? true : false }}
                                fullWidth
                                select
                                variant="filled">
                                <MenuItem key={1} value={true}>
                                    Active
                                </MenuItem>
                                <MenuItem key={0} value={false}>
                                    Inactive
                                </MenuItem>
                            </TextField>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>

                <Grid container spacing={2} className="mt-1 d-flex jc-center pb-1">
                    {!encryptedId ?<Grid item xs={6} md={2}>
                        <Button
                            disabled={
                                !gid ||
                                gidHelper ||
                                !userName ||
                                firstNameHelper ||
                                !userEmail ||
                                emailHelper ||
                                !deptValue ||
                                !divValue ||
                                (!roleValue1 ||  (roleValue1 === 11 && !toolsListString && !encryptedId))

                            }
                            fullWidth
                            onClick={ handleSubmit}
                            className="pt-button--primary">
                             Submit
                        </Button>
                    </Grid>:<></>}
                    {encryptedId ? <Grid item xs={6} md={2}>
                        <Button
                            disabled={
                                !gid ||
                                gidHelper ||
                                !userName ||
                                firstNameHelper ||
                                !userEmail ||
                                emailHelper ||
                                !deptValue ||
                                !divValue ||
                                (!roleValue1 && ((roleValue1 === 11 && (!toolsListString || !filterToolsList) && encryptedId) || (roleValue1 === 11 && !toolsListString && !encryptedId)))

                            }
                            fullWidth
                            onClick={ handleEdit }
                            className="pt-button--primary">
                            Update
                        </Button>
                    </Grid> : <></>}
                    <Grid item xs={6} md={2}>
                        <Button
                            onClick={handleBack}
                            fullWidth
                            className="pt-button--secondary">
                            Back
                        </Button>
                    </Grid>
                </Grid>

                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </Container>
            {isLoader ? <Loader /> : <></>}
        </div>
    );
}
