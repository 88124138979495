import React from "react";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import "./Loader.scss";

const useStyles = makeStyles((theme) => ({
  loadingBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
    flexDirection: "column",
  },
}));

export default function Loader(props) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.loadingBlock}>
        <Dialog
          open={true}
          aria-labelledby="Loader-dialog"
          id="pageLoader"
          BackdropProps={{
            style: { backgroundColor: "var(--white)", opacity: ".75" },
          }}
          PaperProps={{
            style: {
              backgroundColor: "var(--transparent)",
              boxShadow: "none",
              borderColor: "transparent",
            },
          }}>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
            }}>
            <CircularProgress />
            <h3
              style={{
                fontStyle: "italic",
                color: "var(--circular-progress-color)",
              }}>
              {props.loaderText ? props.loaderText : "Loading...."}
            </h3>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
