import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Grid,
    Button,
    TextField,
    MenuItem,
    Container,
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Tooltip,
    IconButton,
} from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import MaterialTable from "material-table";
import { baseAddressURL, tableIcons } from "../../Utility/utility";
import "./registrationForm.scss";
import { styled } from "@mui/system";
import Loader from "./../../Components/Loader/Loader";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { useSelector } from "react-redux";
import LaunchIcon from "@mui/icons-material/Launch";
import Slide from "@mui/material/Slide";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
    getApplicationList,
    getDepartmentMenus,
    getDivisionMenus,
} from "../../Redux/API/api_userManagemnt";
import BlockIcon from "@mui/icons-material/Block";
import {
    GetRequestDetailsById,
    GetToolsList,
    GetTypeOfAccessList,
    RaiseAccessRequest,
    dataforGrid,
    gridTableGetData,
    dyanamiFieldTypeData,
    dialogSubmit,
    handleSubmitRequest,
} from "../../Redux/API/api_raiseRequest";

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    background: #EBF7F8;
    border: 1px solid #ccc;
    outline:none;
    &:hover {
      border-color: "#D1FFF2;
    }

    &:focus {
      outline: 0;    
    }
  `
);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
export default function RequestForm() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [requestId, setRequestId] = useState(0);
    const [requestNumber, setRequestNumber] = useState("");
    const [toolID, setToolid] = useState(0);
    const [name, setName] = useState(userDetails.userFirstName);
    const [gid, setGid] = useState(userDetails.gid);
    const [email, setEmail] = useState(userDetails.userEmail);
    const [dept, setDept] = useState(userDetails.departmentName);
    const [div, setDiv] = useState([]);
    const [divValue, setDivValue] = useState("");
    const [app, setApp] = useState([]);
    const [appValue, setAppValue] = useState("");
    const [tools, setTools] = useState([]);
    const [toolsValue, setToolsValue] = useState(null);
    const [toolsId, setToolsId] = useState("");
    const [toolName, setToolName] = useState("");
    const [typeOfAccess, setTypeOfAccess] = useState([]);
    const [typeOfAccessValue, setTypeOfAccessValue] = useState("");
    const [reason, setReason] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [inputType, setInputType] = useState("");
    const [view, setView] = useState("");
    const [additionalFieldValue, setAdditionalFieldValue] = useState("");
    const [customField, setCustomField] = useState([]);
    const [redirect, setRedirect] = useState(false);
    //* Modal Loader ans Snackbar
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [openFormApprove, setOpenFormApprove] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const [flag, setFlag] = useState(false);
    const handleClose = (e) => {
        setOpen(false);
        if (alertType === "success" && !encryptedId) {
            let idEncrypted = btoaSecure(requestId);
            if (idEncrypted) {
                setFlag(true);
            }
            window.location.href =
                baseAddressURL + "/createRequest?id=" + idEncrypted;
        }
        if (redirect === true) {
            window.location.href = baseAddressURL + "/RPAWorkFlow";
        }
    };

    const [additionalData, setAdditionalData] = useState("");
    const handleInputChange1 = (e) => {
        setAdditionalData(e.target.value);
    };
    const handleCloseFormApprove = () => {
        setOpenFormApprove(false);
    };
    //!Input handlers
    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "division":
                setDivValue(event.target.value);
                break;
            case "application":
                setAppValue(event.target.value);
                getAllTools(event.target.value);
                break;
            case "typeOfAccess":
                setTypeOfAccessValue(event.target.value);
                break;
            case "additionalFieldValue":
                setAdditionalFieldValue(event.target.value);
                break;

            default:
                break;
        }
    };

    //new added
    const [selectedOptionsInputControls, setSelectedOptionsInputControls] =
        useState([]);
    const [inputControlsData, setInputControlsData] = useState([]);
    const [filterInputControls, setFilterInputControls] = useState("");
    function editInputControlsByUser(filterInputControls) {
        var arrayInputControls = filterInputControls.split(",");
        setSelectedOptionsInputControls(
            inputControls.filter((x) => arrayInputControls.includes(x.value))
        );
    }
    let inputControlsArr = [];
    const [inputControlsString, setInputControlsString] = useState("");
    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }
    function onChangeInputControls(value, event) {
        let inputControlsValue = [];
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
            inputControlsValue = [];
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }
        if (event.action === "select-option" && event.option.value === "*") {
            inputControlsValue = inputControls;
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            inputControlsValue = [];
        } else {
            inputControlsValue = value;
        }
        for (let i = 0; i < inputControlsValue.length; i++) {
            if (inputControlsValue[i].value !== undefined)
                inputControlsArr.push(inputControlsValue[i].value);
        }
        setInputControlsString(inputControlsArr.toString());
    }
    const [tID, setTID] = useState(0);
    const [tRequestId, setTRequestId] = useState(0);
    const [tTypeId, setTTypeId] = useState(0);
    const [tDynamicFieldValues, setTDynamicFieldValues] = useState();
    const [tDynamicFieldId, setTDynamicFieldId] = useState();
    const dialogOpener = (rowData) => {
        setInputType(rowData.dynamicFieldTypeName);
        setInputValue(rowData.dynamicFieldName);
        getDynamicFieldTypeData(rowData.dynamicFieldId);
        setTID(rowData.toolId);
        setTRequestId(rowData.requestId);
        setTTypeId(rowData.dynamicFieldTypeID);

        setTDynamicFieldId(rowData.dynamicFieldId);
        //for displaying data
        setAdditionalFieldValue(rowData.dynamicFieldValueIds);
        setInputControlsString(rowData.dynamicFieldValueIds);
        setFilterInputControls(rowData.dynamicFieldValueIds);

        setOpenModal(true);
    };

    const handleReasonForAccess = (e) => {
        setReason(e.target.value);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const columnsForm = [
        {
            title: "Actions",
            render: (rowData) => ApproveDocumentButton(rowData),
            cellStyle: {
                width: 20,
                maxWidth: 20,
            },
            headerStyle: {
                width: 20,
                maxWidth: 20,
            },
        },
        {
            title: "Field Name",
            field: "dynamicFieldName",
            width: "10%",
            align: "left",
        },

        {
            title: "Field Value",
            field: "dynamicFieldValues",
        },
    ];

    //! API Calls
    function getDivision() {
        getDivisionMenus(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId
        )
            .then((response) => {
                setDiv(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getApplicationListData() {
        setIsLoading(true);
        getApplicationList(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId
        )
            .then((response) => {
                setApp(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //gridTableGetData
    const [gridPopulateData, setGridPopulateData] = useState([]);
    const [gridDatacheck, setgridDatacheck] = useState(false);
    function getData(tid, rid) {
        gridTableGetData(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            tid,
            rid
        )
            .then((response) => {
                setGridPopulateData(response);
                let data = response.filter((x) => {
                    return x.dynamicFieldValues == null;
                });
                if (data && data.length > 0) {
                    setgridDatacheck(true);
                }

                setFlag(true);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    const [inputControls, setInputControls] = useState([]);
    function getDynamicFieldTypeData(dynamicFieldTypeId) {
        dyanamiFieldTypeData(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            dynamicFieldTypeId
        )
            .then((response) => {
                setInputControls(response);
                if (filterInputControls && response.length > 0) {
                    editInputControlsByUser(filterInputControls);
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function getTypeOfAccess() {
        GetTypeOfAccessList(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId
        )
            .then((response) => {
                setTypeOfAccess(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function getAllTools(id) {
        setIsLoading(true);
        GetToolsList(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            id
        )
            .then((response) => {
                setTools(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    const [apiCall, setApiCall] = useState([]);
    const [finalRequestId, setFinalRequestId] = useState();
    function getRequestDetails(id) {
        // setIsLoading(true);
        GetRequestDetailsById(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            id
        )
            .then((response) => {
                setApiCall(response);

                setName(response.userName);
                setGid(response[0].gid);
                setDept(response[0].department);
                setEmail(response[0].email);
                setDivValue(response[0].divisionId);
                setAppValue(response[0].applicationId);
                getAllTools(response[0].applicationId);
                setToolsId(response[0].toolId);
                setToolName(response[0].toolName);
                setReason(response[0].reasonForAccess);
                setTypeOfAccessValue(response[0].typeOfAccessId);
                setRequestId(response[0].requestId);
                setFinalRequestId(response[0].requestId);

                if (
                    response &&
                    response.length > 0 &&
                    response[0].typeOfAccessId !== 1
                ) {
                    getData(response[0].toolId, response[0].requestId);
                }

                setIsLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function btoaSecure(value) {
        const { btoa } = require("abab");
        let currDate = new Date().toDateString();
        let valueModified = value + " " + currDate;
        let valueSecured = btoa(btoa(valueModified));
        return valueSecured;
    }
    function atobSecure(value) {
        const { atob } = require("abab");
        let arrValue = atob(atob(value)).split(" ");
        let valueActual = arrValue[0];
        return valueActual;
    }

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            if (sParameterName[0] == sParam) {
                return decodeURIComponent(sParameterName[1]);
            }
        }
        // If parameter is not found, return null or empty string
        return null; // or return '';
    }
    var encryptedId = getURLParameter("id");

    //! Save apis
    var formdata = {
        RequestId: 0,
        RequestNumber: "",
        UserId: userDetails.userId,

        DivisionId: divValue,
        ApplicationId: appValue,

        ToolId: toolsId,

        TypeOfAccessId: typeOfAccessValue ? typeOfAccessValue : null,

        ReasonForAccess: reason,

        CreatedBy: userDetails.userId,
    };

    function submit() {
        setIsLoading(true);
        RaiseAccessRequest(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdata
        )
            .then((response) => {
                if (response.isSuccess === true) {
                    handleSnackOpen(response.message, "success");
                    setRequestId(response.objResult.requestId);
                    setRequestNumber(response.objResult.requestNumber);
                    setToolid(response.objResult.toolId);
                } else {
                    setIsLoading(false);
                    handleSnackOpen("Error While Raising Access Request", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //dataforGrid
    var formdataGrid = {
        ToolId: 107,
        RequestId: 1,
    };

    function gridpopulateData() {
        setIsLoading(true);
        dataforGrid(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataGrid
        )
            .then((response) => {
                setIsLoading(false);
                if (response.isSuccess === true) {
                    handleSnackOpen(response.message, "success");
                    setRequestId(response.objResult.requestId);
                } else {
                    handleSnackOpen("Error While Raising Access Request", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    var formdataDialog = {
        ToolId: tID,

        RequestId: tRequestId,
        DynamicFieldId: tDynamicFieldId,

        DynamicFieldTypeID: tTypeId,
        DynamicFieldValueIds:
            inputType === "Dropdown"
                ? additionalFieldValue
                : inputType === "MultiSelectDropdown"
                    ? inputControlsString
                    : "",
        DynamicFieldValues: additionalData
            ? additionalData
            : openFormApprove
                ? "Not Applicable"
                : "", //added after discussion
    };

    function handleSubmitDialog() {
        setIsLoading(true);
        dialogSubmit(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataDialog
        )
            .then((response) => {
                setIsLoading(false);
                if (response.response === true) {
                    handleSnackOpen(response.responseMsg, "success");
                    setOpenModal(false);
                    setOpenFormApprove(false);
                    window.location.reload();
                } else {
                    handleSnackOpen("Error While Raising Access Request", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function ApproveDocumentButton(rowData) {
        function LaunchData() {
            dialogOpener(rowData);
        }
        function viewToolCaseData() {
            console.log("rowData", rowData);
            setInputType(rowData.dynamicFieldTypeName);
            setInputValue(rowData.dynamicFieldName);
            getDynamicFieldTypeData(rowData.dynamicFieldId);
            setTID(rowData.toolId);
            setTRequestId(rowData.requestId);
            setTTypeId(rowData.dynamicFieldTypeID);
            setTDynamicFieldId(rowData.dynamicFieldId);
            setOpenFormApprove(true);
        }

        return (
            <div>
                <IconButton
                    className="activationButton"
                    aria-label="Launch"
                    onClick={LaunchData}
                    style={{ left: `-12px` }}>
                    <Tooltip title="Launch">
                        <LaunchIcon style={{ color: "#008aa6" }} />
                    </Tooltip>
                </IconButton>

                <IconButton
                    className="activationButton"
                    aria-label="Block"
                    onClick={viewToolCaseData}
                    style={{ left: `20px` }}>
                    <Tooltip title="Click To Save 'Not-Applicable'">
                        <BlockIcon style={{ color: `red` }} />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }

    //handleSubmitRequest
    var formdataRequest = {
        RequestID: finalRequestId, //finalRequestId-->requestId
        UserId: userDetails.userId,
    };
    function submitRequestHandler() {
        setIsLoading(true);
        handleSubmitRequest(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataRequest
        )
            .then((response) => {
                setIsLoading(false);
                if (response.status === 1) {
                    handleSnackOpen(response.message, "success");
                    setRedirect(true);
                    setOpenModal(false);
                }
                else if (response.status === -1) {
                    handleSnackOpen(response.message, "error");
                    // setRedirect(true);
                    //setOpenModal(false);
                }
                else {
                    handleSnackOpen("Error While Raising Access Request", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    useEffect(() => {
        getDivision();
        getApplicationListData();
        getTypeOfAccess();
    }, []);

    useEffect(() => {
        if (encryptedId) {
            getRequestDetails(atobSecure(encryptedId));
        }
        if (tools.length > 0) {
            let filtered = tools.filter((c) => c.id === toolsId);
            setToolsValue(filtered[0]);
        }
    }, [
        encryptedId,
        tools.length > 0,
        filterInputControls,
        gridPopulateData.length > 0,
    ]);

    useEffect(() => {
        if (
            encryptedId &&
            typeOfAccessValue === 1 &&
            gridPopulateData.length === 0
        ) {
            submitRequestHandler();
        }
    }, [encryptedId, typeOfAccessValue === 1, gridPopulateData.length === 0]);

    return (
        <div className="main-component">
            <Container className="box-shadow mb-1" maxWidth="xl">
                <Grid container spacing={2} className="mb-1">
                    <Grid item xs={12}>
                        <h3 className="m-0">Access Request Form</h3>
                    </Grid>
                    {/* <button onClick={getData} >click me</button>*/}

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="GID"
                            id="toolList"
                            disabled={flag}
                            value={gid}
                            inputProps={{ maxLength: 8 }}
                            fullWidth
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Name"
                            id="toolList"
                            value={name}
                            disabled={flag}
                            fullWidth
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Email"
                            id="toolList"
                            value={email}
                            fullWidth
                            disabled={flag}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Department"
                            id="department"
                            name="department"
                            value={dept}
                            disabled={flag}
                            fullWidth
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Division"
                            id="division"
                            name="division"
                            value={divValue}
                            onChange={handleInputChange}
                            disabled={flag}
                            //disabled={requestNumber}
                            fullWidth
                            required
                            select
                            variant="filled">
                            {div.map((item) => (
                                <MenuItem key={item.divisionId} value={item.divisionId}>
                                    {item.divisionName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Application"
                            id="application"
                            name="application"
                            value={appValue}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            disabled={flag}
                            select
                            variant="filled">
                            {app.map((item) => (
                                <MenuItem key={item.applicationId} value={item.applicationId}>
                                    {item.applicationName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Autocomplete
                            id="toolsList"
                            options={tools}
                            getOptionLabel={(option) => option.name}
                            value={toolsValue}
                            disabled={!appValue || flag}
                            onChange={(event, newValue) => {
                                setToolsValue(newValue);
                                if (newValue) {
                                    setToolsId(newValue.id);
                                    setToolName(newValue.name);
                                }
                                if (!newValue) {
                                    setToolName("");
                                    setToolsId(0);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tools List"
                                    variant="filled"
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Type Of Access"
                            id="typeOfAccess"
                            name="typeOfAccess"
                            value={typeOfAccessValue}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            disabled={flag}
                            select
                            variant="filled">
                            {typeOfAccess.map((item) => (
                                <MenuItem key={item.typeOfAccessId} value={item.typeOfAccessId}>
                                    {item.typeOfAccessName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-1">
                    <Grid item xs={4} sm={2} className="d-flex align-self">
                        <b>Reason For Access :-</b>
                    </Grid>
                    <Grid item xs={8} sm={10}>
                        <Textarea
                            aria-label="minimum height"
                            placeholder="Enter Remarks"
                            className="width-100"
                            minRows={2}
                            // disabled={flag}
                            onChange={flag ? "" : handleReasonForAccess}
                            value={reason}
                        />
                    </Grid>
                </Grid>
                {!encryptedId ? (
                    <Grid container spacing={2} className="d-flex jc-center pb-1">
                        <Grid item xs={6} sm={2} md={1}>
                            <Link to={"/"}>
                                <Button className="pt-button--secondary" fullWidth>
                                    Back
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} sm={2} md={1}>
                            <Button
                                className="pt-button--primary"
                                fullWidth
                                disabled={
                                    !divValue || !typeOfAccessValue || !toolsId || !appValue
                                }
                                onClick={submit}>
                                Proceed
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
                {encryptedId && typeOfAccessValue === 2 ? (
                    <Grid container spacing={1} className="py-1">
                        <Grid item xs={12}>
                            <h4 className="m-0 pb-1">Additional Fields/Extra Fields</h4>
                        </Grid>

                        <Grid item xs={12} className="dynamic-grid">
                            <MaterialTable
                                title=""
                                columns={columnsForm}
                                data={gridPopulateData}
                                icons={tableIcons}
                                options={{
                                    toolbar: false,
                                    paging: false,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 20, 30, 50],
                                    rowStyle: (x) => {
                                        if (x.tableData.id % 2) {
                                            return { backgroundColor: "#F2FCFC" };
                                        } else {
                                            return { backgroundColor: "white" };
                                        }
                                    },
                                }}
                            // actions={[
                            //   {
                            //     icon: LaunchIcon,
                            //     tisFreeAction: true,
                            //     onClick: (event, rowData) => dialogOpener(rowData),
                            //   },
                            // ]}
                            />
                        </Grid>
                        <Grid item container spacing={2} className="d-flex jc-center mt-1">
                            <Grid item xs={6} sm={2} md={1}>
                                <Link to={"/"}>
                                    <Button className="pt-button--secondary" fullWidth>
                                        Back
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item xs={6} sm={2} md={1}>
                                <Button
                                    className="pt-button--primary"
                                    fullWidth
                                    // disabled={gridDatacheck}
                                    onClick={submitRequestHandler}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </Container>

            <Dialog
                aria-labelledby="litigation-form--registration"
                open={openModal}
                fullWidth
                TransitionComponent={Transition}
                maxWidth={"md"}>
                <DialogContent
                    className={
                        inputType === "MultiSelectDropdown" ? "dynamic-modal" : ""
                    }>
                    <DialogContentText id="alert-dialog-slide-description">
                        <h5>Additional Fields:-</h5>
                        <Grid container item spacing={2} maxWidth="xl">
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Additional Field"
                                    id="additionalField"
                                    name="additionalField"
                                    value={inputValue}
                                    disabled
                                    fullWidth
                                    variant="filled"
                                />
                            </Grid>
                            {inputType === "Dropdown" ? (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Additional Field Value"
                                        id="additionalFieldValue"
                                        name="additionalFieldValue"
                                        value={additionalFieldValue}
                                        onChange={handleInputChange}
                                        fullWidth
                                        select
                                        variant="filled">
                                        {inputControls.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            ) : (
                                <></>
                            )}
                            {inputType === "MultiSelectDropdown" ? (
                                <Grid item xs={12} sm={6} md={6} className="tool-multi">
                                    <ReactMultiSelectCheckboxes
                                        options={[{ label: "All", value: "*" }, ...inputControls]}
                                        placeholderButtonLabel="Dynamic Controls"
                                        getDropdownButtonLabel={getDropdownButtonLabel}
                                        value={selectedOptionsInputControls}
                                        onChange={onChangeInputControls}
                                        setState={setSelectedOptionsInputControls}
                                        fullWidth
                                    />
                                </Grid>
                            ) : (
                                <></>
                            )}
                            {inputType === "FreeText" ? (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Additional Data"
                                        id="additionalData"
                                        value={additionalData}
                                        onChange={handleInputChange1}
                                        fullWidth
                                        variant="filled"
                                    />
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={6} className="d-flex jc-center width-100">
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                className="pt-button--secondary"
                                id="SupplierLoginBtn"
                                onClick={handleCloseModal}>
                                close
                            </Button>
                        </Grid>
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                onClick={handleSubmitDialog}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseFormApprove(event, reason);
                    }
                }}
                aria-labelledby="amt-form--registration"
                open={openFormApprove}
                maxWidth={"md"}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <h5>Do You Want To Save "Not Applicable" For This Field? </h5>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex jc-center">
                    <Button
                        type="submit"
                        className="pt-button--secondary"
                        id="SupplierLoginBtn"
                        onClick={handleCloseFormApprove}>
                        close
                    </Button>
                    <Button
                        type="submit"
                        className="pt-button--primary"
                        id="SupplierLoginBtn"
                        onClick={handleSubmitDialog}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
            {isLoading === true ? <Loader /> : <></>}
        </div>
    );
}
