import { combineReducers } from "redux";

let tokenInitialState = {
  token: "",
};
let azureToken = {
  token: "",
};

const saveAzureTokenReducer = (state = azureToken, action) => {
  switch (action.type) {
    case "saveAzureToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
const saveTokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "saveAccessToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let userDataInitialState = {
  userData: {
    gid: "",
    roleId: 0,
    roleName: "",
    userEmail: "",
    userFirstName: "",
    userLastName: "",
    userId: 0,
  },
};
const storeUserDetailsReducer = (
  state = userDataInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "storeUserData": {
      return {
        userData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};

let userInitialState = {
    gid: "",
};
const authUserReducer = (state = userInitialState, action) => {
    switch (action.type) {
        case "myIDLogin": {
            return {
                gid: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};

let reqNo = {
  reqNo: "",
};

const saveReqNoReducer = (state = reqNo, action) => {
  switch (action.type) {
    case "saveReqNo": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

let encryptedId = {
    encryptedId: "",
};
const saveUserIdReducer = (state = encryptedId, action) => {
    switch (action.type) {
        case "encryptedId": {
            return {
                id: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};


export const masterReducer = combineReducers({
  saveTokenReducer: saveTokenReducer,
  storeUserDetailsReducer: storeUserDetailsReducer,
  saveAzureTokenReducer: saveAzureTokenReducer,
  authUserReducer: authUserReducer,
    saveReqNoReducer: saveReqNoReducer,
    saveUserIdReducer: saveUserIdReducer,

});
