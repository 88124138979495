import { baseApiURL } from "../../Utility/utility";

export async function GetToolsList(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    id) {
  const options = {
    method: "get",
      headers: {
          Authorization: "Bearer " + siteToken.token,
          UserEmail: userEmail,
          Gid: gid,
          Roleid: roleId,
          AzureToken: azureToken,
          userId: userId,
      },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "accessrequest/GetToolListForApplication?applicationId=" +
        id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetTypeOfAccessList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId
) {
  const options = {
    method: "get",
    
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetMstTypeofAccess",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetTrackerTypeList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  toolId
) {
  const options = {
    method: "get",
   
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetMstTracker?ToolId=" + toolId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetMisBuList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  toolId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetMstMisBu?ToolId=" + toolId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetBuList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  toolId
) {
  const options = {
    method: "get",
  
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetMstBU?ToolId=" + toolId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetAccessTypeList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  toolId
) {
  const options = {
    method: "get",
   
    headers: {
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetMstAccessTypeList?ToolId=" + toolId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetCategoryList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  toolId
) {
  const options = {
    method: "get",
    headers: {
     
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetMstCategory?ToolId=" + toolId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetSOfficeList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  toolId
) {
  const options = {
    method: "get",
    headers: {
     
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetMstOffice?ToolId=" + toolId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function RaiseAccessRequest(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "accessrequest/AddUpdateAccessRequestDetail",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//GetDynamicValuesForGridPopulation
export async function dataforGrid(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  formData
) {
  const options = {
    method: "get",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "accessrequest/GetDynamicValuesForGridPopulation",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetDynamicFieldsConfiguration(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  toolId
) {
  const options = {
    method: "get",
    headers: {
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetDynamicfieldaccess?ToolId=" + toolId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetRequestDetailsById(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  requestId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "accessRequest/GetAccessRequestDetail?RequestId=" +
        requestId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function gridTableGetData(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  ToolId,
  RequestId
) {
  const options = {
    method: "get",
    headers: {
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "accessrequest/GetDynamicValuesForGridPopulation?ToolId=" +
        ToolId +
        "&RequestId=" +
        RequestId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//GetDynamicFieldValuesListForDropdown
export async function dyanamiFieldTypeData(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  DynamicFieldId
) {
  const options = {
    method: "get",
    headers: {
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "accessrequest/GetDynamicFieldValuesListForDropdown?DynamicFieldId=" +
        DynamicFieldId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//AddUpdateToolFieldValueRequestMapping
export async function dialogSubmit(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "accessrequest/AddUpdateToolFieldValueRequestMapping",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function handleSubmitRequest(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "accessrequest/SubmitRequest",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetCardsList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(baseApiURL + "login/GetUICardList", options);
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.log("Error : ", error);
  }
}