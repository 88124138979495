// JavaScript source code
import React from "react";
import { useState, useEffect } from "react";
import { Grid, Container, Button, TextField } from "@mui/material";
import { getRpaData, detailsGetData } from "../../Redux/API/api_userManagemnt";
import "./Workflow.scss";
import {
    GetDynamicFieldsConfiguration,
    GetRequestDetailsById,
    gridTableGetData,
} from "../../Redux/API/api_raiseRequest";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import {
    approveRPA,
    rejectRPA,
    approveL1,
    rejectL1,
    getWorkFlowHistoryData,
} from "../../Redux/API/api_userManagemnt";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";

export default function CompleteDetails() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer); //azure-token
    const UID = useSelector((state) => state.saveUserIdReducer);
    let [isLoader, setIsLoader] = useState(false);
    const [reqDetails, setReqDetails] = useState({});
    const [gridPopulateData, setGridPopulateData] = useState([]);
    const [detailsData, setDetailsData] = useState([]);
    const [requestId, setRequestId] = useState();
    const [requestNo, setRequestNo] = useState("");
    const [status, setStatus] = useState(0);

    function atobSecure(value) {
        const { atob } = require("abab");
        let arrValue = atob(atob(value)).split(" ");
        let valueActual = arrValue[0];
        return valueActual;
    }
    function getURLParameter(sParam) {
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split("&");
      for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        if (sParameterName[0] == sParam) {
          return decodeURIComponent(sParameterName[1]);
        }
      }
      // If parameter is not found, return null or empty string
      return null; // or return '';
    }
    var encryptedId = getURLParameter("id");

    const [rejectReason, setRejectReason] = useState("");
    const handleRejectReason = (e) => {
        setRejectReason(e.target.value);
    };

    function getRPA(value) {
        setIsLoader(true);
        GetRequestDetailsById(
          siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.roleId,
          atobSecure(value)
        )
            .then((response) => {
                setReqDetails(response[0]);
                setStatus(response[0].statusId);
            if (response) {
              getData(response[0].toolId, response[0].requestId);
            }
            setIsLoader(false);
          })
          .catch((error) => {
            console.log("error", error);
          });
    }
   
    //detailsGetData-->new

    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
        if (alertType === "success") {
            window.location.href = "/RPAWorkFlow";
        }
    };
   
    function getData(tid, rid) {
        setIsLoader(true);
        detailsGetData(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            tid,
            rid
        )
            .then((response) => {
               
                setDetailsData(response);
               
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    const [openFormApprove, setOpenFormApprove] = useState(false);
    const [openFormReject, setOpenFormReject] = useState(false);

    const handleCloseFormApprove = () => {
        setOpenFormApprove(false);
    };
    const handleCloseForm = () => {
        setOpenFormReject(false);
    };
   

    var approveL1FormData = {
        RequestId: requestId,
        Reason: "",
        ActionOwnerUserId: userDetails.userId,
        ActionOwnerEmailId: userDetails.userEmail,

    }
    function approveL1Data() {
        setIsLoader(true);
        approveL1(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            requestId,
            approveL1FormData
        )
            .then((response) => {
                if (response.status == 1) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);
                   // getRPATableData();
                    setOpenFormApprove(false);
                } else {
                    handleSnackOpen("Error while Approving Request.", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //approveRPA
    var approveRPAFormData = {
        RequestId: requestId,
        Reason: "",
        ActionOwnerUserId: userDetails.userId,
        ActionOwnerEmailId: userDetails.userEmail,

    }
    function approveRPAData() {
        setIsLoader(true);
        approveRPA(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            requestId,
            approveRPAFormData
        )
            .then((response) => {
                if (response.status == 1) {
                    handleSnackOpen(response.responseMsg, "success");

                    setIsLoader(false);
                  //  getRPATableData();
                    setOpenFormApprove(false);
                } else {
                    handleSnackOpen("Error While Approving Request.", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //L1 Reject
    var rejectedl1FormData = {
        RequestId: requestId,
        Reason: rejectReason,
        ActionOwnerUserId: userDetails.userId,
        ActionOwnerEmailId: userDetails.userEmail,
        
    }
    function RejectL1Data() {
        setIsLoader(true);
        rejectL1(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            requestId,
            rejectReason,
            rejectedl1FormData
        )
            .then((response) => {
                if (response.status == 1) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);
                   // getRPATableData();
                    setOpenFormReject(false);
                    setRejectReason("");
                } else {
                    handleSnackOpen("Error while Rejecting Request.", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //RPA Reject
    var rejectedRPAFormData = {
        RequestId: requestId,
        Reason: rejectReason,
        ActionOwnerUserId: userDetails.userId,
        ActionOwnerEmailId: userDetails.userEmail,

    }
    function RejectRPAData() {
        setIsLoader(true);
        rejectRPA(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            requestId,
            rejectReason,
            rejectedRPAFormData
        )
            .then((response) => {
                if (response.status == 1) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);
                    //getRPATableData();
                    setOpenFormReject(false);
                    setRejectReason("");
                } else {
                    handleSnackOpen("Error while Rejecting Request.", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }




    function handleClickOpenFormApprove() {

        setOpenFormApprove(true);
        setRequestId(reqDetails.requestId);
        setRequestNo(reqDetails.requestNumber);
        setStatus(reqDetails.statusId);
    }

    function handleClickOpenFormReject() {
        setOpenFormReject(true);
        setRequestId(reqDetails.requestId);
        setRequestNo(reqDetails.requestNumber);
        setStatus(reqDetails.statusId);
    }
    useEffect(() => {
        if (encryptedId) {
            getRPA(encryptedId);
        }
    }, [encryptedId]);

    return (
      <div>
        <div className="main-component mb-1">
          <Container className="box-shadow details-panel" maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <h3 className="header">
                  Request Details:- {reqDetails.requestNumber}
                </h3>
              </Grid>
              <Grid item xs={6} md={8} className="d-flex jc-flex-end p-0">
                <a href="/RPAWorkFlow" className="template-text">
                  &lt; &lt; Back To List Screen
                </a>
              </Grid>
              <Grid item xs={6} md={8} className="d-flex jc-flex-end"></Grid>
            </Grid>

            <Grid container spacing={6} className="pb-1 pt-1">
              <Grid item xs={4} md={1} className="input-label">
                <span>Name :-</span>
              </Grid>
              <Grid item xs={8} md={3}>
                <input
                  name="myInput"
                  disabled
                  defaultValue={reqDetails.userName}
                  className="detail-input"
                />
              </Grid>
              <Grid item xs={4} md={1} className="input-label">
                <span>Email :-</span>
              </Grid>
              <Grid item xs={12} md={3}>
                <input
                  name="myInput"
                  disabled
                  defaultValue={reqDetails.email}
                  className="detail-input"
                />
              </Grid>
              <Grid item xs={4} md={1} className="input-label">
                <span>GID :-</span>
              </Grid>
              <Grid item xs={12} md={3}>
                <input
                  name="myInput"
                  disabled
                  defaultValue={reqDetails.gid}
                  className="detail-input"
                />
              </Grid>
              <Grid item xs={4} md={1} className="input-label">
                <span>Department:-</span>
              </Grid>
              <Grid item xs={12} md={3}>
                <input
                  name="myInput"
                  disabled
                  defaultValue={reqDetails.department}
                  className="detail-input"
                />
              </Grid>
              <Grid item xs={4} md={1} className="input-label">
                <span>Division:-</span>
              </Grid>
              <Grid item xs={12} md={3}>
                <input
                  name="myInput"
                  disabled
                  defaultValue={reqDetails.division}
                  className="detail-input"
                />
              </Grid>
              <Grid item xs={4} md={1} className="input-label">
                <span>Application:-</span>
              </Grid>
              <Grid item xs={12} md={3}>
                <input
                  name="myInput"
                  disabled
                  defaultValue={reqDetails.applicationName}
                  className="detail-input"
                />
              </Grid>
              <Grid item xs={4} md={1} className="input-label">
                <span>ToolName:-</span>
              </Grid>
              <Grid item xs={12} md={3}>
                <input
                  name="myInput"
                  disabled
                  defaultValue={reqDetails.toolName}
                  className="detail-input"
                />
              </Grid>
              <Grid item xs={4} md={1} className="input-label">
                <span>Type Of Access:-</span>
              </Grid>
              <Grid item xs={12} md={3}>
                <input
                  name="myInput"
                  disabled
                  defaultValue={reqDetails.typeOfAccess}
                  className="detail-input"
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="mt-1">
              <Grid item xs={4} md={1} className="input-label pt-12px">
                <span>Remarks:-</span>
              </Grid>
              <Grid item xs={12} md={11} className="pt-12px">
                <input
                  name="myInput"
                  disabled
                  defaultValue={reqDetails.reasonForAccess}
                  className="detail-input"
                />
              </Grid>

              <Grid item xs={4} md={1} className="input-label pt-12px mt-12px">
                {reqDetails.statusName == "Rejected By L2 Approver" ||
                reqDetails.statusName == "Rejected By L1 Approver" ? (
                  <span>Rejected Remarks:-</span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={11} className="pt-12px mt-12px">
                {reqDetails.statusName == "Rejected By L2 Approver" ||
                reqDetails.statusName == "Rejected By L1 Approver" ? (
                  <input
                    name="myInput"
                    disabled
                    defaultValue={reqDetails.rejectedRemarks}
                    className="detail-input"
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6} className="pb-1">
              <Grid item xs={12}>
                <table className="width-100 details-table">
                  <tbody>
                    {reqDetails.typeOfAccess == "General User" &&
                      detailsData.map((item, index) => (
                        <tr key={index}>
                          <td className="fieldNameColumn">
                            {item.dynamicFieldName}:-
                          </td>
                          <td>
                            <input
                              name="myInput"
                              disabled
                              defaultValue={item.dynamicFieldValues}
                              className="detail-input"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Grid>
            </Grid>

            {(userDetails.roleId === 11 &&
              status === 1 &&
              reqDetails.email !== userDetails.userEmail) ||
            (userDetails.roleId === 12 && status === 2) ||
            (userDetails.roleId === 9 && status === 1) ||
            (userDetails.roleId === 9 && status === 2) ? (
              <Grid
                container
                spacing={2}
                className="mt-1 d-flex jc-center pb-1">
                <Grid item xs={6} md={1}>
                  <Button
                    onClick={handleClickOpenFormApprove}
                    fullWidth
                    className="pt-button--primary">
                    Approve
                  </Button>
                </Grid>
                <Grid item xs={6} md={1}>
                  <Button
                    onClick={handleClickOpenFormReject}
                    fullWidth
                    className="pt-button--secondary">
                    Reject
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            <Dialog
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  handleCloseFormApprove(event, reason);
                }
              }}
              aria-labelledby="litigation-form--registration"
              open={openFormApprove}
              maxWidth={"md"}>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <h5>Do You Want To Approve {requestNo} Request ? </h5>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  className="pt-button--secondary"
                  id="SupplierLoginBtn"
                  onClick={handleCloseFormApprove}>
                  close
                </Button>
                {userDetails.roleId === 11 ||
                (userDetails.roleId === 9 && status === 1) ? (
                  <Button
                    type="submit"
                    className="pt-button--primary"
                    id="SupplierLoginBtn"
                    onClick={approveL1Data}>
                    Submit
                  </Button>
                ) : (
                  <></>
                )}
                {userDetails.roleId === 12 ||
                (userDetails.roleId === 9 && status === 2) ? (
                  <Button
                    type="submit"
                    className="pt-button--primary"
                    id="SupplierLoginBtn"
                    onClick={approveRPAData}>
                    Submit
                  </Button>
                ) : (
                  <></>
                )}
              </DialogActions>
            </Dialog>

            <Dialog
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  handleCloseForm(event, reason);
                }
              }}
              aria-labelledby="litigation-form--registration"
              open={openFormReject}
              maxWidth={"md"}>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <Grid container spacing={2}>
                    <h5>Please provide reason to reject {requestNo} request</h5>
                    <Grid item xs={12}>
                      <TextField
                        label="Reason"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        value={rejectReason}
                        onChange={handleRejectReason}
                        variant="filled"></TextField>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  className="pt-button--secondary"
                  id="SupplierLoginBtn"
                  onClick={handleCloseForm}>
                  close
                </Button>
                {userDetails.roleId === 11 ||
                (userDetails.roleId === 9 && status === 1) ? (
                  <Button
                    type="submit"
                    className="pt-button--primary"
                    id="SupplierLoginBtn"
                    disabled={!rejectReason}
                    onClick={RejectL1Data}>
                    Submit
                  </Button>
                ) : (
                  <></>
                )}
                {userDetails.roleId === 12 ||
                (userDetails.roleId === 9 && status === 2) ? (
                  <Button
                    type="submit"
                    className="pt-button--primary"
                    id="SupplierLoginBtn"
                    disabled={!rejectReason}
                    onClick={RejectRPAData}>
                    Submit
                  </Button>
                ) : (
                  <></>
                )}
              </DialogActions>
            </Dialog>

            <SnackbarCustom
              open={open}
              message={snackMessage}
              alertType={alertType}
              handleClose={handleClose}
            />
          </Container>
          {isLoader ? <Loader /> : <></>}
        </div>
      </div>
    );
}
